import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { EmailOrPhone, PasswordForm, SigninForm, SignupForm, VerificationForm } from "../login-screen/forms";

export interface TokenResponse {
	token: string
}

@Injectable({
	providedIn: 'root'
})
export class AuthService extends BaseService {

	login(signinForm: SigninForm): Observable<TokenResponse> {
		return this.post<TokenResponse>('/auth/signin', JSON.stringify(signinForm));
	}

	signup(signupForm: SignupForm): Observable<never> {
		return this.post<never>('/auth/signup', JSON.stringify(signupForm))
	}

	sendCode(form: EmailOrPhone): Observable<any> {
		return this.post<any>('/auth/code/send', JSON.stringify(form))
	}

	verifyCode(form: VerificationForm): Observable<any> {
		return this.post<any>('/auth/code/verify', JSON.stringify(form))
	}

	setPassword(form: PasswordForm): Observable<any> {
		return this.post<any>('/auth/password/set', JSON.stringify(form))
	}

	token(): Observable<TokenResponse> {
		return this.get<TokenResponse>('/auth/token', false);
	}

	logout(): Observable<any> {
		return this.get<any>('/auth/logout', false);
	}
}
